<template>
  <div
    class="d-flex surfe-plan-container"
  >
    <b-card class="mr-2">
      <b-card-img
        :src="toolsInPlan"
        class="surfe-plan-image"
      />
    </b-card>
    <b-alert
      class="surfe-plan-alert"
      show
      dismissible
    >
      <p class="surfe-plan-alert-h">
        Use credits that are already included in your Surfe plan ✅
      </p>
      <p class="surfe-plan-alert-t">
        <b>You have credits from tools such as Apollo.io, DropContact, Hunter, RocketReach, ZeroBounce, and more.</b> <br>
        Surfe runs these tools sequentially to ensure the highest chance of success in finding contact details.<br>
        Make sure to use your plans’ credits first before connecting to other tools.
      </p>
    </b-alert>
  </div>
</template>

<script>
import {
  BAlert, BCard, BCardImg,
} from 'bootstrap-vue'
import toolsInPlan from '@/assets/images/tools-included-in-plan.png'

export default {
  components: {
    BAlert, BCard, BCardImg,
  },
  data() {
    return {
      toolsInPlan,
    }
  },
}
</script>

<style scoped>
.card-body  {
  padding: 0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
  width: 281px;
  height: 100%;
  border: 1px solid #00BD9D;
  border-radius: 6px;
}

.card {
  margin-bottom: 0;
}

.surfe-plan-container {
  height: 160px;
  margin-bottom: 40px
}

.surfe-plan-image {
  height: 150px;
  border-radius: 6px
}

.surfe-plan-alert {
  height: 100%;
  flex: 2;
  background-color: white !important;
  border-radius: var(6px, 0.375rem);
  border: 1px solid var(#00BD9D, #00BD9D);
  background: var(#FFFFFF, #FFF);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
  padding: 32px;
}

.surfe-plan-alert-h {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
}

.surfe-plan-alert-t {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
}
</style>
