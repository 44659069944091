<template>
  <div>
    <b-alert
      v-if="hasKasprKeyMapped"
      show
      variant="warning"
      class="py-3 px-2"
    >
      <div class="mx-2">
        <div class="d-flex align-items-center">
          <feather-icon
            icon="AlertCircleIcon"
            size="16"
            class="mr-1"
          />
          <b>Important Update: Kaspr API Connection</b>
        </div>
        <br>
        As of Nov 1, 2023, old Kaspr plans won’t support API integration. To keep your connection active, please switch to a new Kaspr plan.
        <b-link
          href="https://help.kaspr.io/"
        >
          Contact Kaspr support team
        </b-link>
      </div>
    </b-alert>
    <ToolsIncludedInPlanBanner />
    <h4 class="mb-5">
      Email & Phone enrichment
    </h4>

    <b-row class="match-height mb-0">
      <Tool
        v-for="tool in enrichment"
        :key="tool.name"
        :tool="tool"
        category="enrichment"
      />
    </b-row>
    <b-row>
      <b-col
        lg="6"
        md="6"
      >
        <zoom-info-tool v-if="tools" />
      </b-col>
    </b-row>

    <b-row class="my-10">
      <b-col md="4">
        <p class="mb-3 font-small-3 font-weight-bolder text-secondary text-accent-3">
          Use Surfe email finder credits first
        </p>
        <p class="mt-0 font-small-3 text-secondary text-darken-3">
          Start the enrichment cascade with Surfe credits, and use your connected tools after.
        </p>
      </b-col>

      <b-col
        md="1"
        class="text-center"
      >
        <b-form-checkbox
          v-model="account.settings.useResellerFirst"
          switch
          class="mb-1"
          :disabled="!account.isAdmin"
          @input="updateAccount('useResellerFirst')"
        />
      </b-col>
    </b-row>

    <h4 class="mb-5">
      Email automation
    </h4>

    <b-row class="match-height mb-2">
      <Tool
        v-for="tool in emailAutomation"
        :key="tool.name"
        :tool="tool"
        category="emailAutomation"
      />
    </b-row>

    <h4 class="mb-5">
      Email validation
    </h4>

    <b-row class="match-height mb-2">
      <Tool
        v-for="tool in emailValidation"
        :key="tool.name"
        :tool="tool"
        category="emailValidation"
      />
    </b-row>

    <h4 class="mb-5">
      Missing your tools?
    </h4>

    <b-row class="match-height mb-2">
      <b-col
        lg="4"
        md="6"
      >
        <SuggestTool />
      </b-col>
    </b-row>

    <h4 class="mb-5">
      Enrichment Logs
    </h4>

    <b-row class="match-height pb-4">
      <b-col
        offset-lg="2"
        class="mx-auto overflow-auto"
        style="max-height: 30vh"
      >
        <enrichment-log />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BCol, BRow, BFormCheckbox, BLink, BAlert,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { trackEvent } from '@core/utils/tracking'
import ToolsIncludedInPlanBanner from '@/views/features/ToolsIncludedInPlanBanner.vue'
import ZoomInfoTool from './components/ZoomInfoTool.vue'
import SuggestTool from './components/SuggestTool.vue'
import Tool from './components/Tool.vue'
import EnrichmentLog from './components/EnrichmentLog.vue'

export default {
  components: {
    ToolsIncludedInPlanBanner,
    BAlert,
    BLink,
    ZoomInfoTool,
    SuggestTool,
    BCol,
    BRow,
    BFormCheckbox,
    Tool,
    EnrichmentLog,
  },
  data() {
    return {
      enrichment: [],
      emailAutomation: [],
      emailValidation: [],
    }
  },
  computed: {
    ...mapState({
      tools: state => state.api.tools,
      hasKasprKeyMapped: state => {
        const kaspr = state.api.tools?.enrichment?.KASPR
        return kaspr && kaspr.apiKey
      },
      account: state => state.api.account,
      featureFlags: state => state.api.featureFlags,
    }),
  },
  async mounted() {
    await this.$store.dispatch('api/loadTools')
    await this.$store.dispatch('api/loadEnrichmentLogs')
    await this.$store.dispatch('api/loadFeatureFlags')

    // Salesloft URL
    const slClientID = process.env.VUE_APP_SALESLOFT_CLIENT_ID
    const slRedirectURL = encodeURI('https://account.leadjet.io/features/oauth-cb/SALESLOFT')
    const slAuthURL = `https://accounts.salesloft.com/oauth/authorize?client_id=${slClientID}&redirect_uri=${slRedirectURL}&response_type=code`

    // Outreach URL
    const orClientID = process.env.VUE_APP_OUTREACH_CLIENT_ID
    const orRedirectURL = encodeURI(
      `${window.location.origin}/features/oauth-cb/OUTREACH`,
    )
    const orAuthURL = `https://api.outreach.io/oauth/authorize?client_id=${orClientID}&redirect_uri=${orRedirectURL}&response_type=code&scope=prospects.all+users.all+sequenceStates.all+tasks.all+sequences.all+sequenceSteps.all`

    this.enrichment = [{
      name: 'DROPCONTACT',
      label: 'DropContact',
      logo: require('@/assets/images/tools/dropcontact.png'),
    }, {
      name: 'SKRAPP',
      label: 'Skrapp',
      logo: require('@/assets/images/tools/skrapp.png'),
    }, {
      name: 'HUNTER',
      label: 'Hunter.io',
      logo: require('@/assets/images/tools/hunter.png'),
    }, {
      name: 'LUSHA',
      label: 'Lusha',
      logo: require('@/assets/images/tools/lusha.png'),
    }, {
      name: 'KENDO',
      label: 'Kendo',
      logo: require('@/assets/images/tools/kendo.png'),
    }, {
      name: 'SNOV',
      label: 'Snov',
      logo: require('@/assets/images/tools/snov.png'),
      bothKeys: true,
    }, {
      name: 'VOILANORBERT',
      label: 'Voila Norbert',
      logo: require('@/assets/images/tools/voilanorbert.png'),
    }, {
      name: 'KASPR',
      label: 'Kaspr',
      logo: require('@/assets/images/tools/kaspr.png'),
    }, {
      name: 'DATAGMA',
      label: 'Datagma',
      logo: require('@/assets/images/tools/datagma.png'),
    }, {
      name: 'SIGNALHIRE',
      label: 'Signal Hire',
      logo: require('@/assets/images/tools/signalhire.png'),
    }, {
      name: 'COGNISM',
      label: 'Cognism',
      logo: require('@/assets/images/tools/cognism.png'),
    }, {
      name: 'ROCKETREACH',
      label: 'Rocket Reach',
      logo: require('@/assets/images/tools/rocketreach.png'),
    }, {
      name: 'LEADIQ',
      label: 'LeadIQ',
      logo: require('@/assets/images/tools/leadiq.png'),
    }]

    // TODO: Reveal again after we fix enrichment for Google & Planhat
    if (this.account.companyKey !== 'GSHEETSGOOGLE.COM' && this.account.companyKey !== 'GSHEETSSURFE.COM' && this.account.companyKey !== 'HUBSPOT3285366' && this.account.companyKey !== 'GSHEETSAWS97192908' && this.account.companyKey !== 'SALESFORCE00D58000000aSktEAE') {
      this.enrichment.push({
        name: 'APOLLO',
        label: 'Apollo',
        logo: require('@/assets/images/tools/apollo.png'),
      })
    }

    const getEmailAutomation = () => {
      const isOutreachEnabled = !!this.featureFlags?.flags.OUTREACH_INTEGRATION_ENABLED?.value
      const salesloft = {
        name: 'SALESLOFT',
        label: 'Salesloft',
        logo: require('@/assets/images/tools/salesloft.png'),
        oauthUrl: slAuthURL,
      }
      const outreach = {
        name: 'OUTREACH',
        label: 'Outreach',
        logo: require('@/assets/images/tools/outreach.png'),
        oauthUrl: orAuthURL,
      }
      const lemlist = {
        name: 'LEMLIST',
        label: 'Lemlist',
        logo: require('@/assets/images/tools/lemlist.png'),
      }
      return isOutreachEnabled ? [salesloft, outreach, lemlist] : [salesloft, lemlist]
    }

    this.emailAutomation = getEmailAutomation()

    this.emailValidation = [{
      name: 'ZEROBOUNCE',
      label: 'Zerobounce',
      logo: require('@/assets/images/tools/zerobounce.png'),
    }]

    // Check of OAuth success
    if (this.$route.query.tool) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Surfe now connected to ${this.$route.query.tool}`,
          icon: 'SaveIcon',
          variant: 'success',
        },
      })
      await trackEvent({
        type: 'dashboard-tools-email_automation-edited',
        props: { isConnected: true, name: this.$route.query.tool },
      })
    }
  },
  methods: {
    async updateAccount(key) {
      try {
        await this.$store.dispatch('api/updateAccount', this.account)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Account settings saved',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
        await trackEvent({ type: 'dashboard-settings-feature_toggled', props: { feature: key, isEnabled: this.account.settings[key] } })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        await this.$store.dispatch('api/loadAccount')
        throw error
      }
    },
  },
}
</script>
