<template>
  <b-card class="border-white">
    <b-row>
      <b-col>
        <p class="mb-4 font-weight-bold">
          Suggest a tool for us to integrate with
        </p>

        <div class="d-flex mb-3">
          <b-form-input
            v-model="toolName"
            placeholder="Tool Name"
          />
        </div>
        <b-form-textarea
          v-model="comment"
          placeholder="Description (optional)"
          rows="3"
          max-rows="6"
        />
        <div
          class="d-flex mt-3"
        >
          <b-button
            variant="terciary"
            block
            :disabled="isSubmitDisabled"
            @click="submit"
          >
            Submit
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BFormInput, BRow, BButton, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { trackEvent } from '@core/utils/tracking'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BFormTextarea,
  },
  data() {
    return {
      toolName: '',
      comment: '',
    }
  },
  computed: {
    isSubmitDisabled() {
      return !this.toolName
    },
  },
  methods: {
    async submit() {
      await this.$store.dispatch('api/addToolSuggestion', {
        tool: this.toolName,
        comment: this.comment,
      })
      await trackEvent({
        type: 'dashboard-tools-form_sent',
        props: {
          name: this.toolName,
          description: this.comment,
        },
      })
      this.toolName = ''
      this.comment = ''
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Thank you for your suggestion!',
          icon: 'SaveIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>
