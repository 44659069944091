<template>
  <b-card
    v-if="tool"
    :class="tool.connected?'border-success':'border-white'"
  >
    <b-row>
      <b-col
        lg="2"
        md="2"
        class="pr-0"
      >
        <b-img
          style="width:100%;max-width:60px"
          :src="logo"
          :alt="label"
        />
      </b-col>
      <b-col>
        <p class="font-weight-bold">
          {{ label }}
        </p>

        <div
          style="display:flex"
        >
          <b-form-input
            v-model="tool.jwt.privateKey"
            placeholder="Private Key"
            @change="update"
          />

          <b-form-input
            v-model="tool.jwt.username"
            placeholder="Username"
            class="ml-1"
            @change="update"
          />

          <b-form-input
            v-model="tool.jwt.clientID"
            placeholder="Client ID"
            class="ml-1"
            @change="update"
          />
        </div>
        <div
          class="d-flex mt-1"
        >
          <div
            v-if="tool.connected"
            class="d-flex flex-inline"
          >
            <b-form-checkbox
              v-model="tool.emailDisabled"
              name="email-checkbox"
              :value="false"
              :unchecked-value="true"
              @change="update"
            >
              Email
            </b-form-checkbox>
            <b-form-checkbox
              v-model="tool.phoneDisabled"
              name="phone-checkbox"
              :value="false"
              :unchecked-value="true"
              class="ml-1"
              @change="update"
            >
              Phone
            </b-form-checkbox>
            <b-form-checkbox
              v-model="tool.alwaysRun"
              name="always-run-checkbox"
              class="ml-1"
              @change="update"
            >
              Always Run
            </b-form-checkbox>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BCard, BFormInput, BImg, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import { trackEvent } from '@core/utils/tracking'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BImg,
    BFormCheckbox,
  },
  data() {
    return {
      name: 'ZOOMINFO',
      label: 'ZoomInfo',
      logo: require('@/assets/images/tools/zoominfo.png'),
      category: 'enrichment',
    }
  },
  computed: {
    ...mapState({
      tool: state => {
        if (state.api.tools.enrichment) {
          return state.api.tools.enrichment.ZOOMINFO
        }
      },
    }),
  },
  methods: {
    async update() {
      // Only update if all 3 fields are filled or empty.
      // We want to update if all 3 empty, in case the user wants to delete this tool.
      if (this.tool.jwt.privateKey === '' && (this.tool.jwt.clientID !== '' || this.tool.jwt.username !== '')) return
      if (this.tool.jwt.clientID === '' && (this.tool.jwt.privateKey !== '' || this.tool.jwt.username !== '')) return
      if (this.tool.jwt.username === '' && (this.tool.jwt.privateKey !== '' || this.tool.jwt.clientID !== '')) return

      await this.$store.dispatch('api/updateTool', {
        ...this.tool,
        name: 'ZOOMINFO',
        category: this.category,
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Your change is saved!',
          icon: 'SaveIcon',
          variant: 'success',
        },
      })

      const isConnected = this.tool.jwt.privateKey !== '' && this.tool.jwt.clientID !== '' && this.tool.jwt.username !== ''
      await trackEvent({
        type: 'dashboard-tools-enrichment-edited',
        props: {
          isConnected,
          name: this.tool.name,
          isEmailChecked: isConnected && !this.tool.emailDisabled,
          isPhoneChecked: isConnected && !this.tool.phoneDisabled,
          isAlwaysRunChecked: isConnected && this.tool.alwaysRun,
          priority: this.tool.order,
        },
      })
    },
  },
}
</script>
