<template>
  <app-collapse
    accordion
    type="margin"
  >
    <span
      v-for="log in enrichmentLogs"
      :key="log.id"
    >
      <app-collapse-item
        :title="createLogTitle(log)"
        title-size-class="custom-title-size"
      >
        <p
          v-for="(provider,p) in log.queriedProviders"
          :key="p"
          class="lh-1"
        >
          Tool: {{ getProviderName(provider) }} - Email:
          {{ createProviderOutcome(log.emails, provider) }}, Phone:
          {{ createProviderOutcome(log.phones, provider) }}
        </p>
        <p class="lh-1">All queried tools: {{ getQueriedToolsNames(log.queriedProviders) }}</p>
      </app-collapse-item>
    </span>
  </app-collapse>
</template>

<script>
import { mapState } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  computed: {
    ...mapState({
      enrichmentLogs: state => state.api.enrichmentLogs,
    }),
  },
  methods: {
    createLogTitle(log) {
      const date = new Date(log.createdAt).toLocaleDateString(
        undefined,
        {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        },
      )
      return `${date}: Enrich profile ${log.profileURL}`
    },
    createProviderOutcome(arr, provider) {
      if (!arr) return
      const obj = arr.find(element => element.providers.includes(provider))
      if (!obj) return 'not found'
      if (obj.skip && obj.values.length > 0) return `found, skipped (reason: ${obj.skipReason})`
      return 'found'
    },
    getProviderName(provider) {
      if (provider.includes('_PHONE_RESELLER')) {
        return `${provider.replace('_PHONE_RESELLER', '')} (by Surfe) - Mobile`
      }

      if (provider.includes('_RESELLER')) {
        return `${provider.replace('_RESELLER', '')} (by Surfe)`
      }

      if (provider.includes('_PHONE')) {
        return `${provider.replace('_PHONE', '')} - Mobile`
      }

      if (provider.includes('LEADJET')) {
        return 'DROPCONTACT (by Surfe)'
      }

      return provider
    },
    getQueriedToolsNames(tools) {
      const deduplicated = new Set(tools.map(x => this.getProviderName(x)))
      return Array.from(deduplicated).join(', ')
    },
  },
}
</script>

<style>
.custom-title-size {
  font-size: 0.9rem !important;
}
</style>
