<template>
  <b-col
    v-if="!shouldHide(tool.label)"
    lg="4"
    md="4"
  >
    <b-card :class="info.connected?'border-success':'border-white'">
      <b-row>
        <b-col
          lg="2"
          class="pr-0"
        >
          <b-img
            style="width:100%;max-width:60px"
            :src="tool.logo"
            :alt="tool.label"
          />
        </b-col>
        <b-col>
          <p class="font-weight-bold">
            {{ tool.label }}
          </p>

          <div
            v-if="tool.oauthUrl"
            style="display:flex"
          >
            <b-button
              variant="terciary"
              block
              @click="toggleConnect"
            >
              {{ info.connected?'Disconnect':'Connect' }}
            </b-button>
          </div>

          <div
            v-else
            style="display:flex"
          >
            <b-form-input
              v-model="info.apiKey"
              placeholder="API Key"
              @change="update"
            />

            <b-form-input
              v-if="tool.bothKeys"
              v-model="info.apiKey2"
              placeholder="API Key 2"
              class="ml-1"
              @change="update"
            />
          </div>
          <div
            v-if="category === 'enrichment'"
            class="mt-1"
          >
            <div
              v-if="info.connected"
              class="d-flex"
              style="align-items:center"
            >
              <b-form-checkbox
                v-model="info.emailDisabled"
                name="email-checkbox"
                :value="false"
                :unchecked-value="true"
                @change="update"
              >
                Email
              </b-form-checkbox>
              <b-form-checkbox
                v-model="info.phoneDisabled"
                name="phone-checkbox"
                :value="false"
                :unchecked-value="true"
                class="ml-1"
                @change="update"
              >
                Phone
              </b-form-checkbox>
              <b-form-checkbox
                v-model="info.alwaysRun"
                name="always-run-checkbox"
                class="ml-1"
                @change="update"
              >
                Always Run
              </b-form-checkbox>

              <b-form-select
                v-model="info.order"
                :options="orderOptions"
                class="ml-2"
                style="max-width:70px"
                @change="update"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BImg, BButton, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapState } from 'vuex'
import { trackEvent } from '@core/utils/tracking'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BImg,
    BButton,
    BFormCheckbox,
    BFormSelect,
  },
  props: {
    tool: {
      type: Object,
      default: () => ({}),
    },
    category: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      orderOptions: Array.from(Array(15).keys()),
    }
  },
  computed: {
    ...mapState({
      tools: state => state.api.tools,
    }),
    info() {
      return {
        previousOrder: this.tools[this.category][this.tool.name]?.order,
        ...this.tools[this.category][this.tool.name],
      }
    },
  },
  methods: {
    shouldHide(label) {
      if (label !== 'Salesloft' && label !== 'Outreach' && label !== 'Lemlist') return false

      const hasOutreach = this.tools.emailAutomation.OUTREACH.connected
      const hasSalesloft = this.tools.emailAutomation.SALESLOFT.connected
      const hasLemlist = this.tools.emailAutomation.LEMLIST.connected

      // If one is connected show according to its own state
      if (label !== 'Salesloft' && hasSalesloft) return true
      if (label !== 'Outreach' && hasOutreach) return true
      if (label !== 'Lemlist' && hasLemlist) return true

      return false
    },
    async update() {
      if (this.tool.bothKeys) {
        if (this.info.apiKey !== '' && this.info.apiKey2 === '') return
        if (this.info.apiKey === '' && this.info.apiKey2 !== '') return
      }

      await this.$store.dispatch('api/updateTool', {
        ...this.info,
        name: this.tool.name,
        category: this.category,
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Your change is saved!',
          icon: 'SaveIcon',
          variant: 'success',
        },
      })

      if (this.category === 'enrichment') await this.trackEnrichmentTool()
      if (this.category === 'emailValidation') await this.trackEmailValidationTool()
    },
    async trackEmailValidationTool() {
      await trackEvent({
        type: 'dashboard-tools-email_validation-edited',
        props: {
          isConnected: this.info.apiKey !== '',
          name: this.tool.name,
        },
      })
    },
    async trackEnrichmentTool() {
      const isConnected = this.info.apiKey !== ''
      await trackEvent({
        type: 'dashboard-tools-enrichment-edited',
        props: {
          isConnected,
          name: this.tool.name,
          isEmailChecked: isConnected && !this.info.emailDisabled,
          isPhoneChecked: isConnected && !this.info.phoneDisabled,
          isAlwaysRunChecked: isConnected && this.info.alwaysRun,
          priority: this.info.order,
        },
      })
    },
    async toggleConnect() {
      if (this.info.connected) {
        await this.$store.dispatch('api/disconnectTool', this.tool.name)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tool disconnected',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
        await trackEvent({
          type: 'dashboard-tools-email_automation-edited',
          props: { isConnected: false, name: this.tool.name },
        })
        await this.$router.replace({ query: null })
      } else {
        window.location = this.tool.oauthUrl
      }
    },
  },
}
</script>
